import React from "react";

const WhatsAppIcons = () => {
  // Números y enlaces de WhatsApp
  const personalNumber = "4053153991";
  const groupLink = "https://chat.whatsapp.com/FQgDYpHc2sBGtpl3qcaTKD";

  // Función para manejar el clic en el icono personal
  const handlePersonalClick = () => {
    window.open(`https://wa.me/${personalNumber}`, "_blank");
  };

  // Función para manejar el clic en el icono grupal
  //   const handleGroupClick = () => {
  //     window.open(groupLink, "_blank");
  //   };

  return (
    <div className="whatsapp-icons">
      {/* Icono de WhatsApp Personal */}
      <div className="whatsapp-icon" onClick={handlePersonalClick}>
        <i className="fa fa-whatsapp" aria-hidden="true"></i>
        <span className="tooltip">WhatsApp Personal</span>
      </div>

      {/* Icono de WhatsApp Grupal */}
      {/* <div className="whatsapp-icon whatsapp-secondary" onClick={handleGroupClick}>
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                <span className="tooltip">WhatsApp Grupal</span>
            </div> */}
    </div>
  );
};

export default WhatsAppIcons;
