import React from "react";
import Hero from "./home-components/hero";
import Features from "./home-components/features";
import About from "./home-components/about";
import AboutIntro from "./home-components/about-intro";
import HowWorks from "./home-components/how-works";
import Screenshot from "./home-components/screenshots";
import SpecialFeatures from "./home-components/special-features";
import ScreenshotsV2 from "./home-components/screenshots-v2";
import Pricing from "./home-components/pricing";
import VideoPromo from "./home-components/video-promo";
import Testimonial from "./home-components/testimonial";
import Subscribe from "./home-components/subscription";
import FactsSection from "./home-components/facts-section";
import Blog from "./home-components/blog";
import Contact from "./home-components/contact";
import WhyPaint from "./home-components/why-paint";
import homedata from "../data/home.json";

const Home = () => {
  const servicesGalery = homedata.screenshots;
  const servicesGalery2 = homedata.screenshots2;
  const servicesGalery3 = homedata.screenshots3;
  const servicesGalery4 = homedata.screenshots4;

  return (
    <div>
      <Hero />
      <AboutIntro />
      <About />
      <HowWorks />
      {/* <WhyPaint /> */}
      <Features />
      <Screenshot
        mainTitle={servicesGalery.sectionHeading.title}
        mainDesc={servicesGalery.sectionHeading.desc}
        screenshot={servicesGalery.screenshot}
      />
      <Screenshot
        mainTitle={servicesGalery2.sectionHeading.title}
        mainDesc={servicesGalery2.sectionHeading.desc}
        screenshot={servicesGalery2.screenshot}
      />
      <Screenshot
        mainTitle={servicesGalery3.sectionHeading.title}
        mainDesc={servicesGalery3.sectionHeading.desc}
        screenshot={servicesGalery3.screenshot}
      />
      <Screenshot
        mainTitle={servicesGalery4.sectionHeading.title}
        mainDesc={servicesGalery4.sectionHeading.desc}
        screenshot={servicesGalery4.screenshot}
      />
      <Testimonial />
      <FactsSection />
      {/* <SpecialFeatures />
      <ScreenshotsV2 />
      <Pricing />
      <Subscribe />
      <VideoPromo />
      <Blog /> */}
      <Contact />
    </div>
  );
};

export default Home;
