import React, { Component } from "react";
import homedata from "../../data/home.json";

class Features extends Component {
  render() {
    let factsInfo = homedata.features;
    let sectionHeadingInfo = factsInfo.sectionHeading;
    let featureContent = factsInfo.singleFeature;
    let publicUrl = process.env.PUBLIC_URL;

    const certificationSectionStyle = {
      width: "100%",
      padding: "20px",
      background: `#DEEEFC`,
      backgroundSize: "cover",
      color: "#7B87A6",
      textAlign: "center",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    };

    const certificationTextStyle = {
      padding: "20px",
      background: "#FFFF",
      borderRadius: "8px",
    };

    const ulStyle = {
      listStyleType: "none",
      padding: 0,
    };

    const liStyle = {
      marginBottom: "10px",
    };

    return (
      <section id="features" className="pt100 pb0 bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb40">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: sectionHeadingInfo.title,
                  }}
                ></h2>
                <hr className="lines" />
                {/* <p>{sectionHeadingInfo.desc}</p> */}
              </div>
            </div>
          </div>

          <div className="row">
            {/* Cuadro de Certificación de Competencias */}

            {/* Cuadro de Feature Content */}
            <div className="col-md-12 mt-4">
              <div className="row">
                <div className="col-md-6">
                  {featureContent.featurePart1.map((element, i) => (
                    <div key={i} className="media single-feature-item">
                      <div className="feature-icon">
                        {/* <i className={element.icon}></i> */}
                        <img src={element.icon} alt="service-3" />
                      </div>
                      <div className="media-body">
                        <h5>{element.title}</h5>
                        <p>{element.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-md-6">
                  {featureContent.featurePart2.map((element, i) => (
                    <div key={i} className="media single-feature-item">
                      <div className="feature-icon">
                        {/* <i className={element.icon}></i> */}
                        <img src={element.icon} alt="service-3" />
                      </div>
                      <div className="media-body">
                        <h5>{element.title}</h5>
                        <p className="saltoDeLinea">{element.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Features;
