import React, { useState } from "react";
import homedata from "../../data/home.json";
import axios from "axios";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";
import WhatsAppIcons from "./whatsapp";

const Contact = () => {
  const [cargando, setCargando] = useState(false);

  const envioDeCorreo = (e) => {
    e.preventDefault(); // Previene la actualización de la página

    // Obtén el formulario y sus campos
    const form = e.target.form;
    const nombre = form.name.value;
    const correo = form.email.value;
    //const subject = form.subject.value;
    const mensaje = form.message.value;

    //Cambiar estos datos para cada pagina y su respectivo contacto
    const nombreProp = "John 316 Painting";
    const correoProp = "contact@john316painting.com";
    const pagina = "john316painting.com";

    const data = {
      nombre,
      correo,
      mensaje,
      nombreProp,
      correoProp,
      pagina,
    };

    // Realizar la solicitud POST utilizando Axios
    setCargando(true);
    axios
      .post("https://taeconta.com/api/public/api/correos/publicos", data)
      .then((response) => {
        console.log("Correo enviado con éxito:", response.data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Correo enviado exitosamente",
          showConfirmButton: false,
          timer: 1500,
        });
        setCargando(false);
        // clearState(e)
      })
      .catch((error) => {
        console.error("Error al enviar el correo:", error);
        Swal.fire(
          "Error al enviar el correo",
          error.message + ", " + error.response.data.message,
          "error"
        );
        setCargando(false);
        // clearState(e)
      });
  };

  let contactInfo = homedata.contact;
  let sectionHeadingInfo = contactInfo.sectionHeading;
  let publicUrl = process.env.PUBLIC_URL;
  let inlineStyle = {
    backgroundImage: "url(" + publicUrl + contactInfo.bgImg + ")",
  };

  return (
    <section
      id="contact"
      className="pt100 pb100 bg-parallax"
      style={inlineStyle}
    >
      <WhatsAppIcons />
      <div className="color-overlay opacity-8"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="contact-block text-center">
              <div className="col-sm-12">
                <h3>{sectionHeadingInfo.title}</h3>
                <p className="subtitle">{sectionHeadingInfo.desc}</p>
              </div>

              <form id="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <input
                      name="name"
                      className="contact-name form-control input-box"
                      id="contact-name"
                      type="text"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      name="email"
                      className="contact-email form-control input-box"
                      id="contact-email"
                      type="email"
                      placeholder="E-mail"
                      required
                    />
                  </div>
                  <div className="col-sm-12">
                    <input
                      name="subject"
                      className="contact-subject form-control input-box"
                      id="contact-subject"
                      type="text"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="col-sm-12">
                    <textarea
                      name="message"
                      className="contact-message form-control textarea-box"
                      id="contact-message"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="col-sm-12">
                    <button className="primary-btn" onClick={envioDeCorreo}>
                      {cargando ? (
                        <ReactLoading type="cylon" />
                      ) : (
                        <>
                          <i
                            className="fa fa-paper-plane"
                            aria-hidden="true"
                          ></i>{" "}
                          {contactInfo.submitBtnLabel}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </form>
              <div className="col-lg-8 offset-lg-2 mt-5">
                <h3>Hours of Operation</h3>
                <p>Monday – Friday: 8:00 AM – 6:00 PM</p>
                <p>Saturday: 8:00 AM – 1:00 PM</p>
              </div>
            </div>

            <div className="contact-block text-center">
              <h3>Service Areas</h3>
              <a
                href="https://maps.app.goo.gl/FRQRTajYvNa89Jkm7"
                target="_balnk"
                style={{ color: "gray" }}
              >
                All Oklahoma
              </a>
              <div className="embed-responsive embed-responsive-16by9">
                {/* Mapa de Google */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3334017.5563579523!2d-98.71671339999999!3d35.30904955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87ac3911b1ac4f9d%3A0x5d1ccf33400aea48!2sOklahoma%2C%20EE.%20UU.!5e0!3m2!1ses-419!2smx!4v1733785017858!5m2!1ses-419!2smx"
                  width="600"
                  height="200"
                  className="embed-responsive-item"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
